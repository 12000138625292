export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
  const isBrowser = () => typeof window !== "undefined";
  return isBrowser() && window.localStorage.getItem("punchInUser")
    ? JSON.parse(window.localStorage.getItem("punchInUser"))
    : {}
}
const setUser = (user) => {
  if(user){
    const isBrowser = () => typeof window !== "undefined"
    isBrowser() && window.localStorage.setItem("punchInUser", JSON.stringify(user));

  }
}


export const handleLogin = async (credentials) => {
  // if (username === `john` && password === `pass`) {
  //   return setUser({
  //     username: `john`,
  //     name: `Johnny`,
  //     email: `johnny@example.org`,
  //   })
  // }
  //
  // return false

  const options = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(credentials) // body data type must match "Content-Type" header
    //body: credentials // body data type must match "Content-Type" header
  }
  try{
    const response = fetch('https://api.punch-in.co.uk/auth', options)
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data.token && data.user) {
        data.user.token = data.token;
        setUser(data.user);
        return data;
      } else {
        return Promise.reject(new Error(JSON.stringify(data)))
      }
    });
    return response;
  } catch (err) {
    return Promise.reject(new Error(400));
  }
}

export const isLoggedIn = () => {
  const user = getUser();
  console.log(`from auth, user is: ${Object.keys(user)}`)

  return user && user.firstname ? !!user.firstname : false;
}

export const logout = callback => {
  setUser({})
  callback()
}
